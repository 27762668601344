import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Flaky Records', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Flaky Records is a Glasgow based record label.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'FLAKY',
  name: 'RECORDS',
  subtitle: 'A phonygraphic record label',
  cta: 'WHAT?',
};

// ABOUT DATA
export const aboutData = {
  img: 'flaky-logo.png',
  paragraphOne:
    'Flaky Records is a Glasgow-based record label facilitated by a no-hit wonder who releases' +
    ' records that no-one listens to.',
  paragraphTwo: ' ',
  paragraphThree: ' ',
  resume: '', // if no resume, the button will not show up
};
// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'lyle-03.png',
    title: 'Lyle Christine',
    info: 'Patience of a toddler. Radioactive levels of cynicism.',
    info2: "Currently working on a follow-up to 2020's 'Funraiser'.",
    url: 'https://www.lylechristine.com',
    repo: 'https://lylechristine.bandcamp.com', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'The Three Shits',
    info:
      'Stalwarts of the Scottish indie scene, The Three Shits have survived drug addiction, self-harm, overdoses, jail - and' +
      " worst of all, an unwise early-career foray into Smashing Pumpkins-style pedestrian 'synth-pop'.",
    info2:
      "All three Shits are proud COVID-19 carriers and can't wait to get back on the road, doing what they love most, " +
      ' which is massive amounts of heroin.',
    url: 'https://scene360.com/art/98250/morbid-sculptures/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: ' ',
  btn: 'inbox@flakyrecords.com',
  email: 'inbox@flakyrecords.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://www.twitter.com/PithyArtist',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
